.careserviceBackground {
  background: linear-gradient(to right, rgb(33, 33, 33), rgba(255, 0, 0, 0)),
    url(../img/csbg1.png) center center no-repeat;
  background-size: cover;
  height: 350px;
}
.autismbg {
  background: linear-gradient(to right, rgb(33, 33, 33), rgba(255, 0, 0, 0)),
    url(../img/autbg.jpg) center center no-repeat;
  background-size: cover;

  height: 350px;
}
.ldbg {
  background: linear-gradient(to right, rgb(33, 33, 33), rgba(255, 0, 0, 0)),
    url(../img/slider3.jpeg) center center no-repeat;
  background-size: cover;

  height: 350px;
}
.maisbg {
  background: linear-gradient(to right, rgb(33, 33, 33), rgba(255, 0, 0, 0)),
    url(../img/bg.jpg) center center no-repeat;
  background-size: cover;
  height: 350px;
}
.resibg {
  background: linear-gradient(to right, rgb(33, 33, 33), rgba(255, 0, 0, 0)),
    url(../img/resb.jpg) center center no-repeat;
  background-size: cover;

  height: 350px;
}
.dombg {
  background: linear-gradient(to right, rgb(33, 33, 33), rgba(255, 0, 0, 0)),
    url(../img/domb.jpg) center center no-repeat;
  background-size: cover;

  height: 350px;
}
.ecvm {
  background: linear-gradient(to right, rgb(33, 33, 33), rgba(255, 0, 0, 0)),
    url(../img/evm.jpeg) center center no-repeat;
  background-size: cover;
  height: 350px;
}
.abbg {
  background: linear-gradient(to right, rgb(33, 33, 33), rgba(255, 0, 0, 0)),
    url(../img/aboutside.jpg) center center no-repeat;
  background-size: cover;
  height: 350px;
}
.awardb {
  background: linear-gradient(to right, rgb(33, 33, 33), rgba(255, 0, 0, 0)),
    url(../img/award.png) center center no-repeat;
  background-size: cover;
  height: 350px;
}
.welcoming {
  background: linear-gradient(to right, rgb(33, 33, 33), rgba(255, 0, 0, 0)),
    url(../img/divimg.jpg) center center no-repeat;
  background-size: cover;
  height: 350px;
}
.teambg {
  background: linear-gradient(to right, rgb(33, 33, 33), rgba(255, 0, 0, 0)),
    url(../img/teambg.jpg) center center no-repeat;
  background-size: cover;
  height: 350px;
}
.blogb {
  background: linear-gradient(to right, rgb(33, 33, 33), rgba(255, 0, 0, 0)),
    url(../img/blogb.jpg) center center no-repeat;
  background-size: cover;
  height: 350px;
}
.pfb {
  background: linear-gradient(to right, rgb(33, 33, 33), rgba(255, 0, 0, 0)),
    url(../img/pfb.jpg) center center no-repeat;
  background-size: cover;
  height: 350px;
}
.chb {
  background: linear-gradient(to right, rgb(33, 33, 33), rgba(255, 0, 0, 0)),
    url(../img/chb.gif) center center no-repeat;
  background-size: cover;
  height: 350px;
}
.laws {
  background: linear-gradient(to right, rgb(33, 33, 33), rgba(255, 0, 0, 0)),
    url(../img/law.jpg) center center no-repeat;
  background-size: cover;
  height: 350px;
}
