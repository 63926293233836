.hoverUnderline:hover {
  text-decoration: underline;
}
.slideHover {
  background-color: #843A77;
  background: linear-gradient(to right, #f28c28 49%, #843A77 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
}
.slideHover:hover {
  background-position: left bottom;
}
