/* subedit */
.navbar-sub {
  margin-top: 1rem;
  /* width: 60%; */
  color: #000;
  font-family: "Jost", sans-serif;
  font-size: 18px;
}
/* subeditend */

.navbar .dropdown-menu div[class*="col"] {
  margin-bottom: 1rem;
  margin-top: 3rem;
}

.navbar .dropdown-menu {
  border: none;
  margin-top: 0.8rem;
  /* margin-left: -10px; */
  width: 98%;
  background-color: #ffffff !important;
  border-radius: 12px;
}

/* breakpoint and up - mega dropdown styles */
@media screen and (min-width: 992px) {
  
  /* remove the padding from the navbar so the dropdown hover state is not broken */
  .navbar {
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #000;

  }

  /* remove the padding from the nav-item and add some margin to give some breathing room on hovers */
  .navbar .nav-item {
    padding: 0.5rem 0.5rem;
    margin: 0 0.25rem;
  }

  /* makes the dropdown full width  */
  .navbar .dropdown {
    position: static;
  }

  .navbar .dropdown-menu {
    width: 100%;
    left: 0;
    right: 0;
    /*  height of nav-item  */
    top: 45px;
    
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s linear;
  }
  .nav-item a {
    color: #000;
  }

  
  .hideOnClick{
    visibility: hidden !important;
    opacity: 0 !important;
    transition: visibility 0s, opacity 0.3s linear !important;
  }

  /* shows the dropdown menu on hover */
  .navbar .dropdown:hover .dropdown-menu,
  .navbar .dropdown .dropdown-menu:hover {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.3s linear;
  }

  .navbar .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.15);
    background-color: #fff;
  }
}

.underline {
  /* border-bottom: 3px solid #843A77; */
  transition: 0.1s;
}

.underline {
  box-shadow: inset 0 1.3em 0 #f28c28;
  color: #ffffff;
  transition: 0.6s;
}

/*******************************/
/******* Section Header ********/
/*******************************/
.section-header {
  position: relative;
  width: 100%;
  max-width: 700px;
  margin: 0 auto 45px auto;
}

.section-header p {
  margin-bottom: 5px;
  position: relative;
  font-size: 22px;
  font-weight: 600;
  text-transform: capitalize;
  color: #843a77;
}

.section-header h2 {
  margin: 0;
  font-size: 45px;
  font-weight: 700;
}

@media (max-width: 991.98px) {
  .section-header h2 {
    font-size: 45px;
  }
}

@media (max-width: 767.98px) {
  .section-header h2 {
    font-size: 40px;
  }
}

@media (max-width: 575.98px) {
  .section-header h2 {
    font-size: 35px;
  }
}

/*******************************/
/********** About CSS **********/
/*******************************/
.about {
  position: relative;
  width: 100%;
  padding: 45px 0;
}

.about .section-header {
  margin-bottom: 30px;
  margin-left: 0;
}

.about .about-img {
  width: 100%;
  height: 100vh;
}

.about .about-tab {
  width: 100%;
}

.about .about-tab .nav.nav-pills .nav-link {
  padding: 8px;
  font-weight: 600;
  background: none;
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  transition: none;
}

.about .about-tab .nav.nav-pills .nav-link:hover,
.about .about-tab .nav.nav-pills .nav-link.active {
  color: #843a77;
  padding-bottom: 7px;
  border-bottom: 3px solid #843a77;
}

.about .about-tab .tab-content {
  padding: 15px 0 0 0;
  background: transparent;
}

.about .about-tab .tab-content .container {
  padding: 0;
}

@media (max-width: 991.98px) {
  .about .section-header {
    margin-top: 30px;
  }
}

/*******************************/
/********* Service CSS *********/
/*******************************/
.service {
  position: relative;
  width: 100%;
  padding: 45px 0 15px 0;
}

.service .service-item {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 45px;
  transition: 0.3s;
}

.service .service-icon {
  position: relative;
  width: 60px;
}

.service .service-icon i {
  position: relative;
  display: block;
  color: #843a77;
  font-size: 60px;
  line-height: 60px;
  margin-top: 5px;
}

.service .service-text {
  position: relative;
  margin-left: 20px;
  padding-left: 20px;
}

.service .service-text::before {
  position: absolute;
  content: "";
  width: 1px;
  height: calc(100% - 10px);
  top: 5px;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
}

.service .service-text::after {
  position: absolute;
  content: "";
  width: 3px;
  height: 40px;
  top: calc(50% - 20px);
  left: -1px;
  background: #843a77;
}

.service .service-text h3 {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 1px;
}

.service .service-text p {
  margin: 0;
}

/*******************************/
/********* Donate CSS **********/
/*******************************/
.donate {
  position: relative;
  width: 100%;
  margin: 45px 0;
  background: rgba(0, 0, 0, 0.5);
}

.donate .donate-content {
  padding: 45px 0 15px 0;
}

.container .donate {
  margin: 90px 0;
}

.container .donate .donate-content {
  padding: 45px 30px 15px 30px;
}

.donate .donate-content .section-header {
  margin-bottom: 30px;
}

.donate .donate-content .section-header h2 {
  color: #ffffff;
}

.donate .donate-text p {
  color: #ffffff;
  font-size: 18px;
}

.donate .donate-form {
  padding: 90px 30px;
  background: #843a77;
}

.donate .donate-form .control-group {
  margin-bottom: 15px;
}

.donate .donate-form .form-control {
  height: 60px;
  color: #ffffff;
  padding: 0 15px;
  border-radius: 0;
  border: 1px solid #ffffff;
  background: transparent;
}

.donate .donate-form .form-control::placeholder {
  color: #ffffff;
  opacity: 1;
}

.donate .donate-form .form-control:-ms-input-placeholder,
.donate .donate-form .form-control::-ms-input-placeholder {
  color: #ffffff;
}

.donate .donate-form .btn-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.donate .donate-form .btn.btn-custom {
  padding: 15px 0;
  width: 100%;
  height: 60px;
  color: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: inset 0 0 0 0 #ffffff;
}

.donate .donate-form .btn.btn-custom:hover,
.donate .donate-form .btn.btn-custom.active {
  color: #843a77;
  border: 1px solid #ffffff;
  box-shadow: inset 0 0 0 30px #ffffff;
}

/*******************************/
/********** Causes CSS *********/
/*******************************/
.causes {
  position: relative;
  width: 100%;
  padding: 45px 0;
}

.causes .causes-carousel {
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-right: -15px;
}

.causes .causes-item {
  margin: 0 15px;
  overflow: hidden;
  background: #f3f6ff;
}

.causes .causes-img {
  overflow: hidden;
}

.causes .causes-img img {
  width: 100%;
  transition: 0.3s;
}

.causes .causes-item:hover img {
  transform: scale(1.1);
}

.causes .causes-progress {
  width: 100%;
  margin-top: 32px;
  padding: 30px 30px 20px 30px;
}

.causes .progress {
  height: 10px;
  border-radius: 0;
  background: #dddddd;
  overflow: visible;
}

.causes .progress .progress-bar {
  position: relative;
  width: 0px;
  background: #843a77;
  overflow: visible;
  transition: 2s;
}

.causes .progress-bar span {
  position: absolute;
  top: -32px;
  right: 0;
  height: 23px;
  display: inline-block;
  padding: 2px 6px;
  background: #843a77;
  color: #20212b;
}

.causes .progress-bar span::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: 23px;
  left: calc(50% - 6px);
  border: 6px solid;
  border-color: #843a77 transparent transparent transparent;
}

.causes .progress-text {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}

.causes .progress-text p {
  margin: 0;
}

.causes .causes-text {
  padding: 0 30px;
}

.causes .causes-text h3 {
  font-size: 22px;
  font-weight: 700;
}

.causes .causes-text p {
  margin: 0;
}

.causes .causes-btn {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}

.causes .causes-btn .btn {
  padding: 10px 0;
  width: 50%;
}

.causes .causes-btn .btn:last-child {
  color: #20212b;
  background: #843a77;
}

.causes .causes-btn .btn:last-child:hover {
  color: #ffffff;
}

/*******************************/
/********** Facts CSS **********/
/*******************************/
.facts {
  position: relative;
  width: 100%;
  min-height: 400px;
  margin: 45px 0;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}

.facts .facts-item {
  display: flex;
  flex-direction: row;
  margin: 25px 0;
}

.facts .facts-item i {
  margin-top: 10px;
  font-size: 60px;
  line-height: 60px;
  color: #843a77;
}

.facts .facts-text {
  padding-left: 20px;
}

.facts .facts-text h3 {
  position: relative;
  display: inline-block;
  color: #ffffff;
  font-size: 45px;
  font-weight: 700;
}

.facts .facts-text h3::after {
  position: absolute;
  top: 0px;
  color: #ffffff;
  font-size: 25px;
  font-family: "Jost", sans-serif;
  font-weight: 900;
}

.facts .facts-text h3.facts-plus::after {
  content: "\f067";
  right: -25px;
}

.facts .facts-text h3.facts-dollar::after {
  content: "\f155";
  right: -18px;
}

.facts .facts-text p {
  color: #ffffff;
  font-size: 22px;
  font-weight: 600;
  margin: 0;
}

/*******************************/
/*********** Team CSS **********/
/*******************************/
.team {
  position: relative;
  width: 100%;
  padding: 45px 0 15px 0;
}

.team .team-item {
  position: relative;
  margin-bottom: 35px;
}
.team .team-img {
  position: relative;
  overflow: hidden;
  border: 4px solid;
  border-image: repeating-linear-gradient(
      135deg,
      #843a77 0 10px,
      #f28c28 0 10px,
      #843a77 0 10px
    )
    8;
  -webkit-mask: conic-gradient(
        from 180deg at top 8px right 8px,
        rgba(190, 51, 190, 0) 90deg,
        #000 0
      )
      var(--_i, 200%) 0 /200% var(--_i, 8px) border-box no-repeat,
    conic-gradient(at bottom 8px left 8px, #0000 90deg, #000 0) 0
      var(--_i, 200%) / var(--_i, 8px) 200% border-box no-repeat,
    linear-gradient(#000 0 0) padding-box no-repeat;
  transition: 0.3s, -webkit-mask-position 0.3s 0.3s;
}
/* new try */

.team .team-img:hover {
  /* border: 3px solid green; */
  --_i: 100%;
  color: #cc333f;
  transition: 0.3s, -webkit-mask-size 0.3s 0.3s;
}
/* new try */

.team .team-img img {
  width: 100%;
  transform: scale(1.1);
  margin-bottom: -15px;
  transition: 0.3s;
}

.team .team-item:hover img {
  margin: -15px 0 0 0;
}

.team .team-text {
  position: absolute;
  width: calc(100% - 40px);
  height: 96px;
  bottom: 0;
  left: 20px;
  padding: 22px 0;
  text-align: center;
  background: #ffffff;
  transition: 0.3s;
  overflow: hidden;
}

.team .team-text h2 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.team .team-text p {
  margin-bottom: 20px;
}

.team .team-social {
  position: relative;
  font-size: 0;
}

.team .team-social a {
  display: inline-block;
  width: 35px;
  height: 35px;
  margin: 0 3px;
  padding: 5px 0;
  text-align: center;
  font-size: 14px;
  color: #4a4c70;
  border: 2px solid #843a77;
  transition: 0.3s;
}

.team .team-social a:hover {
  color: #20212b;
  background: #843a77;
}

.team .team-item:hover .team-text {
  height: 160px;
}

/*******************************/
/******* Testimonial CSS *******/
/*******************************/
.testimonial {
  position: relative;
  width: 100%;
  padding: 45px 0;
}

.testimonial .testimonials-carousel {
  position: relative;
  width: calc(100% + 30px);
  margin: 0 -15px;
}

.testimonial .testimonial-item {
  position: relative;
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.testimonial .testimonial-profile {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.testimonial .testimonial-profile img {
  width: 80px;
  margin-bottom: -1px;
}

.testimonial .testimonial-name {
  padding-left: 15px;
  width: calc(100% - 95px);
}

.testimonial .testimonial-name h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
}

.testimonial .testimonial-name p {
  margin: 0;
  font-style: italic;
}

.testimonial .testimonial-text p {
  margin: 0;
}

.testimonial .owl-dots {
  margin-top: 15px;
  text-align: center;
}

.testimonial .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 0;
  background: #843a77;
}

.testimonial .owl-dot.active {
  background: #20212b;
}

/*******************************/
/******** Volunteer CSS ********/
/*******************************/
.volunteer {
  position: relative;
  width: 100%;
  margin: 45px 0;
  background: rgba(0, 0, 0, 0.5);
}

.volunteer .volunteer-content {
  padding: 30px 0 45px 0;
}

.container .volunteer {
  margin: 90px 0;
}

.container .volunteer .volunteer-content {
  padding: 45px 30px 15px 30px;
}

.volunteer .volunteer-content .section-header {
  margin-bottom: 30px;
}

.volunteer .volunteer-content .section-header h2 {
  color: #ffffff;
}

.volunteer .volunteer-text p {
  color: #ffffff;
  font-size: 18px;
}

.volunteer .volunteer-form {
  padding: 90px 30px;
  background: #843a77;
}

.volunteer .volunteer-form .control-group {
  margin-bottom: 15px;
}

.volunteer .volunteer-form .form-control {
  height: 60px;
  color: #ffffff;
  padding: 0 15px;
  border-radius: 0;
  border: 1px solid #ffffff;
  background: transparent;
}

.volunteer .volunteer-form textarea.form-control {
  height: 120px;
  padding: 15px;
}

.volunteer .volunteer-form .form-control::placeholder {
  color: #ffffff;
  opacity: 1;
}

.volunteer .volunteer-form .form-control:-ms-input-placeholder,
.volunteer .volunteer-form .form-control::-ms-input-placeholder {
  color: #ffffff;
}

.volunteer .volunteer-form .btn.btn-custom {
  padding: 15px 0;
  width: 100%;
  height: 60px;
  color: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: inset 0 0 0 0 #ffffff;
}

.volunteer .volunteer-form .btn.btn-custom:hover {
  color: #843a77;
  border: 1px solid #ffffff;
  box-shadow: inset 0 0 0 30px #ffffff;
}

/*******************************/
/********** Event CSS **********/
/*******************************/
.event {
  position: relative;
  width: 100%;
  padding: 45px 0 15px 0;
}

.event .event-item {
  margin-bottom: 30px;
  background: #f3f6ff;
}

.event .event-item img {
  width: 100%;
}

.event .event-content {
  padding: 30px;
  display: flex;
}

.event .event-meta {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.event .event-meta p {
  position: relative;
  margin-bottom: 8px;
  padding-bottom: 8px;
  white-space: nowrap;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.event .event-meta p:last-child {
  border-bottom: none;
}

.event .event-meta i {
  color: #4a4c70;
  width: 25px;
}

.event .event-text {
  position: relative;
  margin-left: 20px;
  padding-left: 20px;
}

.event .event-text::before {
  position: absolute;
  content: "";
  width: 1px;
  height: calc(100% - 5px);
  top: 6px;
  left: 0;
  background: rgba(0, 0, 0, 0.15);
}

.event .event-text::after {
  position: absolute;
  content: "";
  width: 3px;
  height: 40px;
  top: calc(50% - 20px);
  left: -1px;
  background: #843a77;
}

.event .event-text h3 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 10px;
}

.event .event-text p {
  margin: 0;
}

.event .btn.btn-custom {
  margin-top: 20px;
  padding: 8px 30px;
}

@media (max-width: 575.98px) {
  .event .event-content {
    flex-direction: column;
  }

  .event .event-meta {
    flex-direction: row;
  }

  .event .event-meta p {
    font-size: 14px;
    padding-right: 7px;
  }

  .event .event-meta p:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  .event .event-meta i {
    width: 18px;
  }

  .event .event-text {
    margin: 0;
    padding: 0;
  }

  .event .event-text::before,
  .event .event-text::after {
    display: none;
  }
}

/*******************************/
/*********** Blog CSS **********/
/*******************************/
.blog {
  position: relative;
  width: 100%;
  padding: 45px 0 15px 0;
}

.blog .blog-item {
  margin-bottom: 30px;
  padding-bottom: 30px;
  background: #f3f6ff;
}

.blog .blog-img {
  width: 100%;
}

.blog .blog-img img {
  width: 100%;
}

.blog .blog-text {
  padding: 30px 30px 15px 30px;
}

.blog .blog-text h3 {
  font-size: 22px;
  font-weight: 700;
}

.blog .blog-text h3 a {
  color: #4a4c70;
}

.blog .blog-text h3 a:hover {
  color: #843a77;
}

.blog .blog-text p {
  margin: 0;
}

.blog .blog-meta {
  margin: 0 30px;
  padding-top: 15px;
  display: flex;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.blog .blog-meta p {
  margin: 0;
  color: #777777;
  padding: 0 30px;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.blog .blog-meta p:first-child {
  padding-left: 0;
}

.blog .blog-meta p:last-child {
  padding-right: 0;
  border: none;
}

.blog .blog-meta i {
  color: #4a4c70;
  margin-right: 8px;
}

.blog .pagination {
  margin-bottom: 15px;
}

.blog .pagination .page-link {
  color: #4a4c70;
  border-radius: 0;
  border-color: #4a4c70;
}

.blog .pagination .page-link:hover,
.blog .pagination .page-item.active .page-link {
  color: #843a77;
  background: #4a4c70;
}

.blog .pagination .disabled .page-link {
  color: #999999;
}

/*******************************/
/********* Contact CSS *********/
/*******************************/
.contact {
  position: relative;
  width: 100%;
  padding: 45px 0;
}

.contact .contact-img {
  position: relative;
  width: 100%;
}

.contact .contact-img img {
  width: 100%;
  height: 440px;
  object-fit: cover;
}

.contact .contact-form {
  position: relative;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  margin-top: -220px;
  padding: 30px;
  background: #f3f6ff;
}

@media (max-width: 991.98px) {
  .contact .contact-form {
    max-width: 600px;
  }
}

@media (max-width: 767.98px) {
  .contact .contact-form {
    max-width: 400px;
  }
}

@media (max-width: 575.98px) {
  .contact .contact-form {
    max-width: 300px;
  }
}

.contact .contact-form input {
  padding: 15px;
  background: none;
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.contact .contact-form textarea {
  height: 150px;
  padding: 8px 15px;
  background: none;
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.contact .contact-form .btn.btn-custom {
  width: 100%;
}

.contact .help-block ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

/*******************************/
/******* Single Post CSS *******/
/*******************************/
.single {
  position: relative;
  padding: 45px 0;
}

.single .single-content {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
}

.single .single-content img {
  margin-bottom: 20px;
  width: 100%;
}

.single .single-tags {
  margin: -5px -5px 41px -5px;
  font-size: 0;
}

.single .single-tags a {
  margin: 5px;
  display: inline-block;
  padding: 7px 15px;
  font-size: 14px;
  font-weight: 500;
  color: #4a4c70;
  border: 1px solid #4a4c70;
}

.single .single-tags a:hover {
  color: #ffffff;
  background: #843a77;
  border-color: #843a77;
}

.single .single-bio {
  margin-bottom: 45px;
  padding: 30px;
  background: #f3f6ff;
  display: flex;
}

.single .single-bio-img {
  width: 100%;
  max-width: 100px;
}

.single .single-bio-img img {
  width: 100%;
  border: 15px solid #ffffff;
}

.single .single-bio-text {
  padding-left: 30px;
}

.single .single-bio-text h3 {
  font-size: 20px;
  font-weight: 700;
}

.single .single-bio-text p {
  margin: 0;
}

.single .single-related {
  margin-bottom: 45px;
}

.single .single-related h2 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 25px;
}

.single .related-slider {
  position: relative;
  margin: 0 -15px;
  width: calc(100% + 30px);
}

.single .related-slider .post-item {
  margin: 0 15px;
}

.single .post-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.single .post-item .post-img {
  width: 100%;
  max-width: 80px;
}

.single .post-item .post-img img {
  width: 100%;
}

.single .post-item .post-text {
  padding-left: 15px;
}

.single .post-item .post-text a {
  font-size: 16px;
  font-weight: 600;
}

.single .post-item .post-text a:hover {
  color: #843a77;
}

.single .post-item .post-meta {
  display: flex;
  margin-top: 8px;
}

.single .post-item .post-meta p {
  display: inline-block;
  margin: 0;
  padding: 0 3px;
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
}

.single .post-item .post-meta p a {
  margin-left: 5px;
  color: #999999;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
}

.single .related-slider .owl-nav {
  position: absolute;
  width: 90px;
  top: -55px;
  right: 15px;
  display: flex;
}

.single .related-slider .owl-nav .owl-prev,
.single .related-slider .owl-nav .owl-next {
  margin-left: 15px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4a4c70;
  background: #843a77;
  font-size: 16px;
  transition: 0.3s;
}

.single .related-slider .owl-nav .owl-prev:hover,
.single .related-slider .owl-nav .owl-next:hover {
  color: #843a77;
  background: #4a4c70;
}

.single .single-comment {
  position: relative;
  margin-bottom: 45px;
}

.single .single-comment h2 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 25px;
}

.single .comment-list {
  list-style: none;
  padding: 0;
}

.single .comment-child {
  list-style: none;
}

.single .comment-body {
  display: flex;
  margin-bottom: 30px;
}

.single .comment-img {
  width: 60px;
}

.single .comment-img img {
  width: 100%;
  border-radius: 0;
}

.single .comment-text {
  padding-left: 15px;
  width: calc(100% - 60px);
}

.single .comment-text h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 3px;
}

.single .comment-text span {
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.single .comment-text .btn {
  padding: 3px 10px;
  font-size: 14px;
  font-weight: 500;
  color: #4a4c70;
  background: #dddddd;
  border-radius: 0;
}

.single .comment-text .btn:hover {
  background: #843a77;
}

.single .comment-form {
  position: relative;
}

.single .comment-form h2 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 25px;
}

.single .comment-form form {
  padding: 30px;
  background: #f3f6ff;
}

.single .comment-form form .form-group:last-child {
  margin: 0;
}

.single .comment-form input,
.single .comment-form textarea {
  border-radius: 0;
}

.single .comment-form input:focus,
.single .comment-form textarea:focus {
  border-color: #843a77;
}

/**********************************/
/*********** Sidebar CSS **********/
/**********************************/
.sidebar {
  position: relative;
  width: 100%;
}

@media (max-width: 991.98px) {
  .sidebar {
    margin-top: 45px;
  }
}

.sidebar .sidebar-widget {
  position: relative;
  margin-bottom: 45px;
}

.sidebar .sidebar-widget .widget-title {
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 5px;
  font-size: 25px;
  font-weight: 700;
}

.sidebar .sidebar-widget .widget-title::after {
  position: absolute;
  content: "";
  width: 60px;
  height: 2px;
  bottom: 0;
  left: 0;
  background: #843a77;
}

.sidebar .sidebar-widget .search-widget {
  position: relative;
}

.sidebar .search-widget input {
  height: 50px;
  border: 1px solid #dddddd;
  border-radius: 0;
}

.sidebar .search-widget input:focus {
  box-shadow: none;
  border-color: #843a77;
}

.sidebar .search-widget .btn {
  position: absolute;
  top: 6px;
  right: 15px;
  height: 40px;
  padding: 0;
  font-size: 25px;
  color: #843a77;
  background: none;
  border-radius: 0;
  border: none;
  transition: 0.3s;
}

.sidebar .search-widget .btn:hover {
  color: #4a4c70;
}

.sidebar .sidebar-widget .recent-post {
  position: relative;
}

.sidebar .sidebar-widget .tab-post {
  position: relative;
}

.sidebar .tab-post .nav.nav-pills .nav-link {
  color: #4a4c70;
  background: #843a77;
  border-radius: 0;
}

.sidebar .tab-post .nav.nav-pills .nav-link:hover,
.sidebar .tab-post .nav.nav-pills .nav-link.active {
  color: #843a77;
  background: #4a4c70;
}

.sidebar .tab-post .tab-content {
  padding: 15px 0 0 0;
  background: transparent;
}

.sidebar .tab-post .tab-content .container {
  padding: 0;
}

.sidebar .sidebar-widget .category-widget {
  position: relative;
}

.sidebar .category-widget ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar .category-widget ul li {
  margin: 0 0 12px 22px;
}

.sidebar .category-widget ul li:last-child {
  margin-bottom: 0;
}

.sidebar .category-widget ul li a {
  display: inline-block;
  line-height: 23px;
}

.sidebar .category-widget ul li::before {
  position: absolute;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #843a77;
  left: 1px;
}

.sidebar .category-widget ul li span {
  display: inline-block;
  float: right;
}

.sidebar .sidebar-widget .tag-widget {
  position: relative;
  margin: -5px -5px;
}

.single .tag-widget a {
  margin: 5px;
  display: inline-block;
  padding: 7px 15px;
  font-size: 14px;
  font-weight: 500;
  color: #4a4c70;
  border: 1px solid #4a4c70;
  border-radius: 0;
}

.single .tag-widget a:hover {
  color: #ffffff;
  background: #843a77;
  border-color: #843a77;
}

.sidebar .image-widget {
  display: block;
  width: 100%;
  overflow: hidden;
}

.sidebar .image-widget img {
  max-width: 100%;
  transition: 0.3s;
}

.sidebar .image-widget img:hover {
  transform: scale(1.1);
}

/*******************************/
/********* Footer CSS **********/
/*******************************/
.footer {
  position: relative;
  margin-top: 45px;
  padding-top: 90px;
  background: #20212b;
}

.footer .footer-contact,
.footer .footer-link,
.footer .footer-newsletter {
  position: relative;
  margin-bottom: 45px;
  color: #ffffff;
}

.footer .footer-contact h2,
.footer .footer-link h2,
.footer .footer-newsletter h2 {
  margin-bottom: 30px;
  font-size: 22px;
  font-weight: 700;
  color: #843a77;
}

.footer .footer-link a {
  display: block;
  margin-bottom: 10px;
  color: #ffffff;
  transition: 0.3s;
}

.footer .footer-link a::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .footer-link a:hover {
  color: #843a77;
  letter-spacing: 1px;
}

.footer .footer-contact p i {
  width: 25px;
}

.footer .footer-social {
  position: relative;
  margin-top: 20px;
}

.footer .footer-social a {
  width: 40px;
  height: 40px;
  padding: 6px 0;
}

.footer .footer-social a {
  margin-top: 5px;
}

.footer .footer-newsletter form {
  position: relative;
  width: 100%;
}

.footer .footer-newsletter input {
  margin-bottom: 15px;
  height: 60px;
  border: none;
  border-radius: 0;
}

.footer .footer-newsletter label {
  margin-top: 5px;
  color: #777777;
  font-size: 14px;
  letter-spacing: 1px;
}

.footer .footer-newsletter .btn.btn-custom {
  width: 100%;
  padding: 15px 0;
}

.footer .copyright {
  padding: 0 30px;
}

.footer .copyright .row {
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright p {
  margin: 0;
  color: #999999;
}

.footer .copyright .col-md-6:last-child p {
  text-align: right;
}

.footer .copyright p a {
  color: #ffffff;
}

.footer .copyright p a:hover {
  color: #843a77;
}

@media (max-width: 767.98px) {
  .footer .copyright p,
  .footer .copyright .col-md-6:last-child p {
    margin: 5px 0;
    text-align: center;
  }
}
