.blog {
    position: relative;
    width: 100%;
    padding: 45px 0 15px 0;
}

.blog .blog-item {
    margin-bottom: 30px;
    padding-bottom: 30px;
    background: #f3f6ff;
}

.blog .blog-img {
    width: 100%;
}

.blog .blog-img img {
    width: 100%;
}

.blog .blog-text {
    padding: 30px 30px 15px 30px;
}

.blog .blog-text h3 {
    font-size: 22px;
    font-weight: 700;
}

.blog .blog-text h3 a {
    color: #4a4c70;
}

.blog .blog-text h3 a:hover {
    color: #34beb9;
}

.blog .blog-text p {
    margin: 0;
}

.blog .blog-meta {
    margin: 0 30px;
    padding-top: 15px;
    display: flex;
    border-top: 1px solid rgba(0, 0, 0, .15);
}

.blog .blog-meta p {
    margin: 0;
    color: #777777;
    padding: 0 30px;
    border-right: 1px solid rgba(0, 0, 0, .15);
}

.blog .blog-meta p:first-child {
    padding-left: 0;
}

.blog .blog-meta p:last-child {
    padding-right: 0;
    border: none;
}

.blog .blog-meta i {
    color: #4a4c70;
    margin-right: 8px
}

.blog .pagination {
    margin-bottom: 15px;
}

.blog .pagination .page-link {
    color: #4a4c70;
    border-radius: 0;
    border-color: #4a4c70;
}

.blog .pagination .page-link:hover,
.blog .pagination .page-item.active .page-link {
    color: #34beb9;
    background: #4a4c70;
}

.blog .pagination .disabled .page-link {
    color: #999999;
}
